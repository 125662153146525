import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { CheckBox, Form, Heading, Layer, Text } from 'grommet';
import { Ref, useEffect, useRef, useState } from 'preact/compat';
import { batch, useDispatch } from 'react-redux';

import { PrimaryButton } from '@/components/Buttons';
import ImageSelector from '@/components/ImageSelector';
import Header from '@/components/modals/Header';
import { updateVideoMutationFn } from '@/queries/media';
import { Video } from '@/queries/media/types';
import { getInfiniteAdminPlaylistsQueryOptions } from '@/queries/playlists';
import { AdminPlaylistListItem } from '@/queries/playlists/types';
import { useSelector } from '@/redux/helper';
import { updateAlbumData } from '@/redux/slices/myAlbum/actions';
import { updateHabitatAlbumData } from '@/routes/habitat/components/Album/actions';
import { buildURL, get } from '@/shared/fetch';

import { updateShareModalMediaData } from '../ShareModal/shareModalSlice/actions';
import PlaylistsSelector from './PlaylistsSelector';

export interface EditVideoModalProps {
  videoId: string;
  defaultThumbnailURL?: string;
  defaultTitle?: string;
  canEditPlaylists?: boolean;
  onClose?: () => void;
  onUpdate?: (data: Video) => void;
}

const CONSTRAINTS = { acceptedFormats: ['image/*'] } as const;
const MEDIA_TRANSFORM = {
  maxWidth: 640,
  maxHeight: 360,
  forceAspectRatio: [16, 9] as [number, number],
} as const;

const RECOMMENDED_SETTINGS = {
  ...MEDIA_TRANSFORM,
  fileSize: 1_000_000,
} as const;

const EditVideoModal = ({
  videoId,
  defaultThumbnailURL,
  defaultTitle,
  canEditPlaylists,
  onClose,
  onUpdate,
}: EditVideoModalProps) => {
  const dispatch = useDispatch();
  const [thumbnailURL, setThumbnailURL] = useState(defaultThumbnailURL);
  const [overlayThumbnail, setOverlayThumbnail] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const mediaPreviewRef = useRef<HTMLImageElement>(null);
  const [playlists, setPlaylists] = useState<AdminPlaylistListItem[]>([]);

  const isAdmin = useSelector((state) => state.user.role === 'admin');

  const { data } = useInfiniteQuery({
    ...getInfiniteAdminPlaylistsQueryOptions({ videos: [videoId] }),
    enabled: isAdmin && !!videoId,
  });

  const { mutate, isPending, error, isSuccess } = useMutation({
    mutationFn: updateVideoMutationFn,
    onSuccess: async () => {
      const { media } = await get(buildURL(`/media/${videoId}`));
      const payload = { id: videoId, data: media };
      batch(() => {
        dispatch(updateShareModalMediaData(media));
        dispatch(updateHabitatAlbumData(payload));
        dispatch(updateAlbumData(payload));
      });
      onUpdate?.(media);
      onClose?.();
    },
  });

  const onSubmit = () => {
    mutate({
      _id: videoId,
      thumbnailURL,
      title,
      overlayThumbnail,
      playlists: isAdmin ? playlists.map(({ _id }) => _id) : undefined,
    });
  };

  useEffect(() => {
    if (data?.list) {
      setPlaylists(data.list);
    }
  }, [data]);

  return (
    <Layer onClickOutside={isPending ? undefined : onClose}>
      <div className="flex max-h-[90svh] w-[90vw] max-w-[420px] flex-col">
        <Header onClose={isPending ? undefined : onClose}>Edit Video</Header>

        <div className="grow overflow-auto">
          <div className="p-4">
            <Form onSubmit={onSubmit} className="flex flex-col gap-6">
              <div>
                <Heading margin="0" level="5">
                  Title:
                </Heading>
                <input
                  placeholder="write a short description"
                  value={title}
                  maxLength={100}
                  onChange={({ currentTarget: { value } }) => setTitle(value)}
                  className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                />
              </div>

              {isAdmin && canEditPlaylists && (
                <div>
                  <Heading margin="0" level="5">
                    Playlists:
                  </Heading>
                  <PlaylistsSelector value={playlists} onChange={setPlaylists} />
                </div>
              )}

              <div>
                <Heading margin={{ top: '0', bottom: '5px' }} level="5">
                  Video Thumbnail:
                </Heading>

                {thumbnailURL && (
                  <div className="aspect-video w-full bg-black">
                    <img
                      ref={mediaPreviewRef as Ref<HTMLImageElement>}
                      src={thumbnailURL}
                      alt=""
                      className="preflight preflight-img"
                    />
                  </div>
                )}

                <ImageSelector
                  url={thumbnailURL}
                  previewRef={mediaPreviewRef}
                  placeholder="https://"
                  constraints={CONSTRAINTS}
                  recommendedSettings={RECOMMENDED_SETTINGS}
                  transform={MEDIA_TRANSFORM}
                  filenameSuffix="thumb"
                  onChange={(value) => setThumbnailURL(value)}
                />
              </div>

              <div>
                <CheckBox
                  checked={overlayThumbnail}
                  name="overlayThumbnail"
                  label={
                    <span>
                      Add{' '}
                      <a
                        href="https://zl-media-recordings.s3.us-east-1.amazonaws.com/overlay.png"
                        target="_blank"
                        rel="noreferrer"
                        className="preflight preflight-a inline underline"
                      >
                        default overlay
                      </a>{' '}
                      (the play button) to the thumbnail
                    </span>
                  }
                  onChange={(event) => setOverlayThumbnail(event.currentTarget.checked)}
                />
              </div>

              {error && (
                <div className="self-center">
                  <Text color="red">{error}</Text>
                </div>
              )}

              {isSuccess && (
                <div className="self-center">
                  <Text color="green">Success</Text>
                </div>
              )}

              <div className="self-center">
                <PrimaryButton
                  className=""
                  loading={isPending}
                  type={isSuccess ? 'button' : 'submit'}
                  disabled={!title || !thumbnailURL || isPending}
                  size="large"
                  onClick={isSuccess ? onClose : undefined}
                  label={isSuccess ? 'Close' : 'Submit'}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layer>
  );
};

export default EditVideoModal;
