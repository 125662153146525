import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalComponent } from 'preact';

import { useFollowHabitat } from '@/hooks/habitats';
import { cn } from '@/lib/utils';
import { useSelector } from '@/redux/helper';

interface FollowButtonProps {
  habitatId: string;
  habitatName: string;
  place?: string;
  className?: string;
}

const FollowButton: FunctionalComponent<FollowButtonProps> = ({ habitatId, habitatName, place, className }) => {
  const isLoggedIn = useSelector((state) => state.user.logged);
  const { isFavorited, favIcon, follow, unfollow } = useFollowHabitat(habitatId, {
    trackingData: {
      habitatName,
      habitatId,
      place,
    },
  });

  const onFollow = async (evt: any) => {
    evt.stopPropagation();

    if (isFavorited) {
      unfollow();
    } else {
      follow();
    }
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={onFollow}
      className={cn(
        'preflight preflight-button inline-flex items-center justify-center gap-2 rounded-full px-3 py-1 font-medium text-grey-1',
        isFavorited ? 'bg-beige-light' : 'bg-primary-yellow',
        className,
      )}
    >
      <FontAwesomeIcon icon={favIcon} color="black" spinPulse={favIcon === faSpinner} />
      <span className="">{isFavorited ? 'Following' : 'Follow'}</span>
    </button>
  );
};
export default FollowButton;
