import HighlightsListHeader from './HighlightsListHeader';
import PlaylistList from './HighlightsPlaylistList';
import VideoList from './HighlightsVideoList';

const HighlightsList = {
  Header: HighlightsListHeader,
  VideoList,
  PlaylistList,
};

export default HighlightsList;
