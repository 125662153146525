import { ComponentProps } from 'preact/compat';

import { cn } from '@/lib/utils';

const NewTag = ({ className, children, ...props }: ComponentProps<'span'>) => {
  return (
    <span
      className={cn(
        'inline-flex justify-center rounded-sm bg-text-error-red px-1 py-0.5 align-middle text-[10px]/none font-medium text-white desktop:rounded desktop:px-1.5 desktop:py-1 desktop:text-xs/none',
        className,
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children ?? 'NEW'}
    </span>
  );
};

export default NewTag;
