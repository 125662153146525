import { format } from 'date-fns';
import { ForwardedRef, forwardRef } from 'preact/compat';

import { usePlaylistState } from './hooks';
import NewTag from './NewTag';
import { PlaylistProps, PlaylistRef } from './types';
import { isNew } from './utils';

const Playlist = (
  { playlistIdFromURL, videoIdFromURL, className, play, onVideoChange, onVideoClick }: PlaylistProps,
  ref: ForwardedRef<PlaylistRef>,
) => {
  const { data, videoIndex, videoChangeHandler } = usePlaylistState({
    playlistIdFromURL,
    videoIdFromURL,
    play,
    onVideoChange,
    onVideoClick,
    ref,
  });

  return (
    <div className={className}>
      <ul className="preflight preflight-ul space-y-6">
        {data?.videos.map((video, index) => (
          <li key={video._id} className="scroll-m-2">
            <a
              data-native
              className="preflight preflight-a group relative grid grid-cols-[150px_1fr] gap-4 transition-opacity hover:opacity-80 aria-disabled:opacity-50"
              href={`/highlights?type=highlight&playlistId=${data._id}&videoId=${video._id}`}
              aria-disabled={video.disabled}
              onClick={(e) => {
                e.preventDefault();
                videoChangeHandler(video, index);
                onVideoClick?.(video, index);
              }}
            >
              <div className="preflight relative overflow-hidden rounded desktop:rounded-lg">
                <img
                  className="preflight preflight-img aspect-video"
                  src={video.thumbnailURL}
                  alt={video.title}
                  loading="lazy"
                />
                {videoIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/75">
                    <div className="flex h-6 items-end gap-0.5 desktop:h-10">
                      <span className="h-[60%] w-2 animate-soundbar bg-white desktop:w-4" />
                      <span className="h-[30%] w-2 animate-soundbar bg-white [animation-delay:-2.2s] desktop:w-4" />
                      <span className="h-[75%] w-2 animate-soundbar bg-white [animation-delay:-3.7s] desktop:w-4" />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center">
                <h4 className="preflight preflight-h4 line-clamp-2 text-sm text-primary-green">
                  {isNew(video.creationDate) && <NewTag />} {video.title}
                </h4>
                <span className="preflight preflight-span text-xs text-grey-6">
                  {format(Date.parse(video.creationDate), 'MMM do, yyyy')}
                </span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default forwardRef(Playlist);
