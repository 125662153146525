import { useQueryClient } from '@tanstack/react-query';
import { CheckBox, Form, FormExtendedEvent, Heading, Layer, Select, Text } from 'grommet';
import { clamp } from 'lodash-es';
import { Ref, useEffect, useRef, useState } from 'preact/compat';
import { useDispatch } from 'react-redux';

import { PrimaryButton } from '@/components/Buttons';
import ImageSelector from '@/components/ImageSelector';
import Header from '@/components/modals/Header';
import { useAdminUploadMediaMutation } from '@/hooks/media';
import { getMedia } from '@/queries/media';
import { AdminUploadMediaRequest } from '@/queries/media/types';

import { setShareModalData } from '../ShareModal/shareModalSlice/actions';

export type UploadToTab = 'timeline' | 'curated' | 'pastTalks';

export interface UploadMediaModalProps {
  habitatId: string;
  uploadToTab?: UploadToTab;
  disallowSendToChat?: boolean;
  onClose?: () => void;
}

const CONSTRAINTS = { acceptedFormats: ['image/*', 'video/*'] } as const;
const VIDEO_CONSTRAINTS = { acceptedFormats: ['video/*'] } as const;
const IMAGE_CONSTRAINTS = { acceptedFormats: ['image/*'] } as const;

const MEDIA_TRANSFORM = {
  maxWidth: 1280,
  maxHeight: 720,
  forceAspectRatio: [16, 9] as [number, number],
} as const;

const RECOMMENDED_SETTINGS = {
  ...MEDIA_TRANSFORM,
  fileSize: 100_000_000,
  duration: 600,
} as const;

const THUMBNAIL_TRANSFORM = {
  maxWidth: 640,
  maxHeight: 360,
  forceAspectRatio: [16, 9] as [number, number],
} as const;

const THUMBNAIL_RECOMMENDED_SETTINGS = {
  ...THUMBNAIL_TRANSFORM,
  fileSize: 1_000_000,
} as const;

const UPLOAD_TO_OPTIONS: { label: string; value: UploadToTab }[] = [
  { label: 'Highlights', value: 'curated' },
  { label: 'Expert Talks', value: 'pastTalks' },
  { label: 'Timeline', value: 'timeline' },
];

const UploadMediaModal = ({
  habitatId,
  uploadToTab: uploadToFromProps,
  disallowSendToChat,
  onClose,
}: UploadMediaModalProps) => {
  const dispatch = useDispatch();
  const [mediaURL, setMediaURL] = useState('');
  const [title, setTitle] = useState('');

  const [shouldUseCustomThumbnail, setShouldUseCustomThumbnail] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState('');
  const [overlayThumbnail, setOverlayThumbnail] = useState(true);

  const [shareToAlbum, setShareToAlbum] = useState(true);
  const [pin, setPin] = useState(true);
  const [pinDays, setPinDays] = useState(1);
  const [shouldSendNotification, setShouldSendNotification] = useState(false);

  const videoOnly = uploadToFromProps === 'curated' || uploadToFromProps === 'pastTalks';

  const [uploadTo, setUploadTo] = useState<UploadToTab>(uploadToFromProps ?? 'curated');

  const mediaPreviewRef = useRef<HTMLImageElement | HTMLVideoElement>(null);
  const thumbnailPreviewRef = useRef<HTMLImageElement>(null);
  const queryClient = useQueryClient();

  const isVideo = Boolean(mediaURL.includes('.mp4') || videoOnly);
  const canSendNotification = isVideo && uploadTo === 'curated';

  const { mutate, isPending, error, isSuccess } = useAdminUploadMediaMutation({
    onSuccess: async (data) => {
      const media = await getMedia(data._id);
      dispatch(setShareModalData({ data: media, mediaId: data._id }));
      queryClient.invalidateQueries({ queryKey: ['media', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['videos', 'list'] });
      onClose?.();
    },
  });

  const onSubmit = (e: FormExtendedEvent<{}, HTMLFormElement>) => {
    const { target }: { target: HTMLFormElement } = e as any;
    const data: AdminUploadMediaRequest = {
      habitatId,
      title,
      url: mediaURL,
      hidden: uploadToFromProps ? false : !shareToAlbum,
      type: uploadTo,
      thumbnail: isVideo && shouldUseCustomThumbnail ? { url: thumbnailURL, addOverlay: overlayThumbnail } : undefined,
      sendToChat: disallowSendToChat
        ? undefined
        : { pinUntil: pin ? new Date(Date.now() + pinDays * 24 * 60 * 60 * 1000) : false },
      sendNotification:
        shouldSendNotification && canSendNotification
          ? {
              subject: target.subject.value,
              content: target.content.value,
            }
          : undefined,
    };
    mutate(data);
  };

  useEffect(() => {
    setUploadTo(uploadToFromProps ?? 'curated');
  }, [uploadToFromProps]);

  return (
    <Layer onClickOutside={isPending ? undefined : onClose}>
      <div data-testid="Upload Media Modal Content" className="flex max-h-[90svh] w-[90vw] max-w-[420px] flex-col">
        <Header onClose={isPending ? undefined : onClose}>Upload Media</Header>

        <div className="grow overflow-auto">
          <div className="p-4">
            <Form onSubmit={onSubmit} className="flex flex-col gap-6">
              <div>
                <Heading margin={{ top: '0', bottom: '5px' }} level="5">
                  Media:
                </Heading>

                {mediaURL && (
                  <div className="aspect-video w-full bg-black">
                    {isVideo ? (
                      <video
                        ref={mediaPreviewRef as Ref<HTMLVideoElement>}
                        src={mediaURL}
                        className="preflight preflight-video"
                        autoPlay
                        muted
                        playsInline
                        controls
                      />
                    ) : (
                      <img
                        ref={mediaPreviewRef as Ref<HTMLImageElement>}
                        src={mediaURL}
                        alt="media"
                        className="preflight preflight-img"
                      />
                    )}
                  </div>
                )}

                <ImageSelector
                  url={mediaURL}
                  name="url"
                  previewRef={mediaPreviewRef}
                  placeholder="https://"
                  constraints={videoOnly ? VIDEO_CONSTRAINTS : CONSTRAINTS}
                  recommendedSettings={RECOMMENDED_SETTINGS}
                  transform={MEDIA_TRANSFORM}
                  onChange={(value) => setMediaURL(value)}
                />
              </div>

              {!uploadToFromProps && isVideo && (
                <div>
                  <Heading margin="0" level="5">
                    Video type:
                  </Heading>
                  <Select
                    value={uploadTo}
                    name="type"
                    labelKey="label"
                    valueKey="value"
                    options={UPLOAD_TO_OPTIONS}
                    onChange={({ option }) => setUploadTo(option.value)}
                  />
                </div>
              )}

              {isVideo && (
                <div>
                  <CheckBox
                    checked={shouldUseCustomThumbnail}
                    name="useCustomThumbnail"
                    label="Use custom thumbnail"
                    onChange={(event) => setShouldUseCustomThumbnail(event.currentTarget.checked)}
                  />

                  {shouldUseCustomThumbnail && (
                    <div className="ml-6 mt-2 flex flex-col gap-2">
                      <div>
                        <CheckBox
                          checked={overlayThumbnail}
                          name="overlayThumbnail"
                          label={
                            <span>
                              Add{' '}
                              <a
                                href="https://zl-media-recordings.s3.us-east-1.amazonaws.com/overlay.png"
                                target="_blank"
                                rel="noreferrer"
                                className="preflight preflight-a inline underline"
                              >
                                default overlay
                              </a>{' '}
                              (the play button) to the thumbnail
                            </span>
                          }
                          onChange={(event) => setOverlayThumbnail(event.currentTarget.checked)}
                        />
                      </div>
                      <div>
                        <Heading margin={{ top: '0', bottom: '5px' }} level="5">
                          Thumbnail:
                        </Heading>

                        {thumbnailURL && (
                          <div className="aspect-video w-full bg-black">
                            <img
                              ref={thumbnailPreviewRef}
                              src={thumbnailURL}
                              alt="thumbnail"
                              className="preflight preflight-img"
                            />
                          </div>
                        )}

                        <ImageSelector
                          url={thumbnailURL}
                          name="thumbnailURL"
                          previewRef={thumbnailPreviewRef}
                          placeholder="https://"
                          constraints={IMAGE_CONSTRAINTS}
                          recommendedSettings={THUMBNAIL_RECOMMENDED_SETTINGS}
                          transform={THUMBNAIL_TRANSFORM}
                          filenameSuffix="thumb"
                          onChange={(value) => setThumbnailURL(value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div>
                <Heading margin="0" level="5">
                  Title:
                </Heading>
                <input
                  placeholder="write a short description"
                  name="title"
                  value={title}
                  maxLength={100}
                  onChange={({ currentTarget: { value } }) => setTitle(value)}
                  className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                />
              </div>

              {!uploadToFromProps && (
                <div>
                  <CheckBox
                    checked={shareToAlbum}
                    name="share"
                    label={
                      isVideo
                        ? `Show in ${UPLOAD_TO_OPTIONS.find((opt) => uploadTo === opt.value)?.label} tab`
                        : 'Show in Photos tab'
                    }
                    onChange={(event) => setShareToAlbum(event.currentTarget.checked)}
                  />
                </div>
              )}

              {!disallowSendToChat && (
                <div>
                  <CheckBox
                    checked={pin}
                    name="pin"
                    label={
                      <>
                        Pin for{' '}
                        <input
                          disabled={!pin}
                          defaultValue={pinDays.toString()}
                          onBlur={(e) => {
                            const value = parseInt(e.currentTarget.value, 10);
                            const validValue = clamp(Number.isNaN(value) ? 1 : value, 1, 365);
                            e.currentTarget.value = validValue.toString();
                            setPinDays(validValue);
                          }}
                          className="preflight preflight-input mx-2 inline-block w-10 rounded border border-grey-5 text-center text-grey-1 disabled:bg-grey-5 disabled:opacity-50"
                          type="number"
                          min="1"
                          max="365"
                          pattern="\d{1,3}"
                        />{' '}
                        day{pinDays > 1 ? 's' : ''} in chat
                      </>
                    }
                    onChange={(event) => setPin(event.currentTarget.checked)}
                  />
                </div>
              )}

              {canSendNotification && (
                <div>
                  <CheckBox
                    checked={shouldSendNotification}
                    name="sendNotification"
                    label="Send notification"
                    onChange={(event) => setShouldSendNotification(event.currentTarget.checked)}
                  />
                  {shouldSendNotification && (
                    <div className="ml-[26px] mt-2">
                      <Heading margin="0" level="5">
                        Subject:
                      </Heading>
                      <input
                        placeholder="Subject of the notification"
                        name="subject"
                        className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                      />
                      <Heading margin="0" level="5">
                        Content:
                      </Heading>
                      <textarea
                        placeholder="Content of the notification"
                        name="content"
                        rows={2}
                        maxLength={100}
                        className="preflight preflight-input w-full border-b pb-1 pt-2 text-xl font-bold placeholder:text-xs placeholder:text-grey-4"
                      />
                    </div>
                  )}
                </div>
              )}

              {error && (
                <div className="self-center">
                  <Text color="red">{error}</Text>
                </div>
              )}

              {isSuccess && (
                <div className="self-center">
                  <Text color="green">Success</Text>
                </div>
              )}

              <div className="self-center">
                <PrimaryButton
                  className=""
                  loading={isPending}
                  type={isSuccess ? 'button' : 'submit'}
                  disabled={!title || !mediaURL || isPending}
                  size="large"
                  onClick={isSuccess ? onClose : undefined}
                  label={isSuccess ? 'Close' : 'Submit'}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layer>
  );
};

export default UploadMediaModal;
