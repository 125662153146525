import clsx from 'clsx';

import HabitatLink from '@/components/HabitatLink';
import { Video } from '@/queries/media/types';

interface HomeHighlightVideoInfoBarProps {
  highlight: Video;
  onNextHighlight?: () => void;
}

const HomeHighlightVideoInfoBar = ({ highlight, onNextHighlight }: HomeHighlightVideoInfoBarProps) => (
  <div className="relative z-10 flex items-center bg-primary-green px-4 py-3 text-white desktop:bg-white desktop:py-4 desktop:text-black desktop:shadow-1">
    <div className="flex min-w-0 grow pr-2 text-sm font-bold desktop:pr-4 desktop:text-base">
      <h4
        className={clsx(
          'preflight preflight-h4 inline-flex min-w-0',
          highlight.title && "desktop:before:content-['“'] desktop:after:content-['”']",
        )}
      >
        <span className="min-w-0 grow truncate">{highlight.title || 'Highlight'}</span>
      </h4>
      <span className="shrink-0 mobile:hidden">
        &nbsp;
        {highlight.type === 'curated' ? (
          'added by zoolife'
        ) : (
          <>
            taken by{' '}
            <a
              href={`/u/${highlight.username}`}
              className="preflight preflight-a text-primary-green hover:text-secondary-green"
            >
              @{highlight.username}
            </a>
          </>
        )}
      </span>
    </div>
    <div className="flex shrink-0 gap-2">
      {highlight.habitatId && (
        <HabitatLink
          habitatId={highlight.habitatId._id}
          slug={highlight.habitatId.slug}
          zooSlug={highlight.habitatId.zoo.slug}
          className="preflight preflight-a rounded-full border border-white px-3 py-1 text-center text-xs font-medium text-white hover:bg-white/10 desktop:border-2 desktop:border-primary-green desktop:px-3.5 desktop:py-1.5 desktop:text-sm desktop:font-medium desktop:text-primary-green desktop:hover:bg-beige-light"
        >
          Visit Habitat
        </HabitatLink>
      )}
      <button
        type="button"
        onClick={onNextHighlight}
        className="preflight preflight-button rounded-full border border-transparent bg-white px-3 py-1 text-xs font-medium text-black desktop:bg-primary-green desktop:px-4 desktop:py-2 desktop:text-sm desktop:font-medium desktop:text-white desktop:hover:bg-secondary-green"
        // className="preflight preflight-button rounded-full border border-transparent bg-white px-3 py-1 text-center text-xs font-medium text-black desktop:border-2 desktop:bg-primary-green desktop:px-3.5 desktop:py-1.5 desktop:text-sm desktop:font-medium desktop:text-white desktop:hover:bg-secondary-green"
      >
        Next
      </button>
    </div>
  </div>
);

export default HomeHighlightVideoInfoBar;
