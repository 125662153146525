import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import Can from 'Components/Authorize';
import { Types } from 'Components/Chat/ChatInfo/constants';
import ChatInfoContext from 'Components/Chat/ChatInfo/context';
import CloseButton from 'Components/modals/CloseButton';
import Tagging from 'Components/ShareModal/Tagging';
import EditTitle from 'Components/ShareModal/TitleEdit';
import VideoPlayer from 'Components/VideoPlayer';
import ViewersCount from 'Components/ViewersCount/standalone';
import { Box, Layer } from 'grommet';
import { lazy, Suspense } from 'preact/compat';
import { useEffect, useMemo, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { API_BASE_URL } from 'Shared/fetch';
import { trackMP } from 'Shared/mp';
import useFetch from 'use-http';

import { getMediaQueryOptions } from '@/queries/media';
import { useSelector, useStore } from '@/redux/helper';
import { addTagToMyAlbumContent, removeTagFromMyAlbumContent } from '@/redux/slices/myAlbum/actions';
import { addTagToHabitatAlbumContent, removeTagFromHabitatAlbumContent } from '@/routes/habitat/components/Album/actions';

import { useIsMobileSize } from '../../hooks';
import { getUrlParam, replaceUrlParam } from '../../routes/habitat/helpers';
import { legacyAlternateChatConfiguration } from '../Chat/ChatConfiguration/context';
import Image from '../Image';
import ShareButtons from './ShareButtons';
import style from './style.scss';

const Chat = lazy(() => import('Components/Chat'));
const PubNubWrapper = lazy(() => import('Components/PubNubWrapper'));

/**
 * ShareModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.mediaId] - The ID of the media.
 * @param {boolean} [props.open] - Flag indicating whether the modal is open or not.
 * @param {boolean} [props.isMyAlbum] - Indicating whether the modal is called from MyAlbum component or not.
 * @param {object} [props.data] - The data object containing media information.
 * @param {string} [props.nextId] - The ID of the next media.
 * @param {string} [props.prevId] - The ID of the previous media.
 * @param {Function} props.onClose - The function to close the modal.
 * @param {Function} [props.setShareModalMediaId] - The function to set the media ID for the modal.
 * @param {Function} [props.onNext] - The function to be called when the next button is clicked.
 * @param {Function} [props.onPrev] - The function to be called when the prev button is clicked.
 * @param {string} [props.slug] - The slug of the media.
 * @param {Array} [props.hashtags] - The array of hashtags associated with the media.
 * @param {boolean} [props.isDownloadAllowed] - Flag indicating whether downloading is allowed or not.
 * @param {boolean} [props.shouldLoadPubnub] - Flag indicating whether PubNub should be loaded or not.
 * @param {boolean} [props.isTrailer] - Flag indicating whether the media is a trailer or not.
 * @param {boolean} [props.isDrawer] - Flag indicating whether the content should be rendered in a drawer or not.
 * @param {boolean} [props.skipDefaultCloseHandler] - Flag indicating whether the default close handler should be skipped or not.
 * @returns {JSX.Element|null} The ShareModal component.
 */
const ShareModal = ({
  mediaId,
  open,
  data: inputData,
  nextId,
  prevId,
  onClose,
  setShareModalMediaId,
  onNext,
  onPrev,
  slug: inputSlug,
  hashtags: inputHashtags,
  isDownloadAllowed = true,
  shouldLoadPubnub = false,
  isTrailer = false,
  isDrawer = false, // to render the content in a drawer instead of a popup
  isMyAlbum = false,
  skipDefaultCloseHandler = false,
}) => {
  const queryClient = useQueryClient();
  const store = useStore();
  const isMobileSize = useIsMobileSize() || isDrawer;
  const { put: mediaViewedPut } = useFetch(API_BASE_URL, { credentials: 'include', cachePolicy: 'no-cache ' });
  const isGuestUser = useSelector((state) => !state.user.logged);
  const userId = useSelector((state) => state.user.userId);

  const mediaQueryOptions = getMediaQueryOptions(mediaId);
  const { data: fetchedMedia, refetch } = useQuery(mediaQueryOptions);
  const onUpdateTitle = (newData) => queryClient.setQueryData(mediaQueryOptions.queryKey, newData);

  const data = fetchedMedia ?? inputData;
  const {
    url,
    videoURL,
    rawURL,
    rawVideoURL,
    title,
    htmlURL,
    views,
    userId: ownerId,
    thumbnailURL,
    tags,
    habitatId,
  } = data ?? {};

  const habitat = useSelector((state) => state.allHabitats.find(({ _id }) => _id === habitatId || _id === habitatId?._id));

  const slug = inputSlug ?? (habitat ? `${habitat.zoo.slug}/${habitat.slug}` : undefined);
  const type = inputData?.type ?? fetchedMedia?.type;
  const hashtags = inputHashtags ?? habitat?.shareSettings?.hashtag;
  const videoType = data?.videoType ?? data?.type;

  const chatInfo = useMemo(
    () => ({
      chatType: type === 'photo' ? Types.PHOTO : Types.CLIP,
      toUserId: ownerId,
      previewImage: thumbnailURL,
      mediaId,
    }),
    [mediaId, ownerId, thumbnailURL, type],
  );

  useEffect(() => {
    if (type === 'photo' && mediaId) {
      mediaViewedPut(`/photos/${mediaId}/${userId ?? 'guest'}/viewed`).catch((err) => console.error(err));
    }
  }, [mediaId, type, mediaViewedPut]);

  useEffect(() => {
    if (type !== 'photo' && mediaId) {
      let habitatInfo = store.getState()?.habitat?.habitatInfo;
      if (type === 'trailer') {
        habitatInfo = store.getState()?.allHabitats?.filter((i) => i?._id === data?.habitat?.id)[0];
      }
      trackMP('watch-video', {
        type,
        videoURL,
        title,
        htmlURL,
        mediaId,
        habitatName: habitatInfo?.title,
        habitatId: habitatInfo?._id,
      });
    }
  }, [mediaId, type]);

  const onCloseRef = useRef({ onClose, skip: false });
  useEffect(() => {
    onCloseRef.current.onClose = onClose;
  }, [onClose]);

  // if the modal is closed without clicking anywhere, e.g. called `history.back()`,
  // call onClose to notify the parent component
  useEffect(
    () => () => {
      if (!onCloseRef.current.skip) {
        onCloseRef.current.onClose?.(false);
      }
    },
    [],
  );

  if (!open || !data) {
    return null;
  }

  const onCloseHandler = () => {
    const id = getUrlParam('id');
    // if the modal is closed by clicking the close button, e.g. on mobile, we don't want to call onClose again
    onCloseRef.current.skip = true;
    onClose();
    if (id && !skipDefaultCloseHandler) {
      if (window.history.length > 2) {
        // if history is more than 2 we assume user is navigating we go to previous page
        window.history.back();
      } else {
        // if history is less than 2 it means that the user opened this page from a URL
        // and we go back to the main page route
        window.location.href = `${window.location.origin}${window.location.pathname}`;
      }
    }
  };

  const navButtons = (
    <>
      {(nextId || onNext) && (
        <button
          type="button"
          className={clsx(style.next, isMobileSize && style.mobile)}
          onClick={() => {
            onNext?.();
            if (nextId) {
              setShareModalMediaId?.(nextId);
              route(replaceUrlParam('id', nextId), true);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
      {(prevId || onPrev) && (
        <button
          type="button"
          className={clsx(style.prev, isMobileSize && style.mobile)}
          onClick={() => {
            onPrev?.();
            if (prevId) {
              setShareModalMediaId?.(prevId);
              route(replaceUrlParam('id', prevId), true);
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
    </>
  );

  const content = (
    <>
      <div className={clsx(style.absoluteClose, isMobileSize && style.mobile)}>
        <CloseButton onClick={onCloseHandler} className={style.close} />
      </div>
      {!isMobileSize && navButtons}

      <Box className={clsx(style.shareModalContainer, isMobileSize && style.mobile, isDrawer && style.drawer)}>
        {isMobileSize && <EditTitle data={data} mediaId={mediaId} isTrailer={isTrailer} onUpdate={onUpdateTitle} />}
        <Box direction="row">
          <Box className={style.shareMedia}>
            <Can perform="album:viewer-count" yes={() => <ViewersCount className={style.viewerCount} viewers={views} />} />
            {url && <Image className={clsx(isMobileSize && style.mobile)} src={rawURL} alt="" />}
            {type !== 'photo' && (rawVideoURL || videoURL) && (
              <VideoPlayer
                videoURL={rawVideoURL ?? videoURL}
                className={clsx(style.videoPlayer, isMobileSize && style.mobile)}
                autoPlay
                muted
                isGuest={isGuestUser}
                videoId={mediaId}
                analyticsData={{
                  habitatId,
                  habitatName: habitat?.title,
                  slug,
                  title: data?.title,
                  player: 'ugc-modal',
                  videoType,
                }}
              />
            )}
            {isMobileSize && navButtons}
          </Box>
          <Box width={{ min: '285px', max: '320px' }} background="white" className={style.rightSection}>
            {!isMobileSize && (
              <EditTitle
                data={data}
                mediaId={mediaId}
                isTrailer={isTrailer}
                onUpdate={onUpdateTitle}
                editInPopup={type !== 'photo'}
              />
            )}
            {!isGuestUser && !isTrailer && (videoType === 'clip' || type === 'photo') && (
              <div className={style.taggingWrapper}>
                <Tagging
                  mediaId={mediaId}
                  allTags={tags}
                  userId={userId}
                  type={type === 'photo' ? 'photos' : 'videos'}
                  addTagToContent={isMyAlbum ? addTagToMyAlbumContent : addTagToHabitatAlbumContent}
                  removeTagFromContent={isMyAlbum ? removeTagFromMyAlbumContent : removeTagFromHabitatAlbumContent}
                  onUpdate={refetch}
                />
              </div>
            )}
            {!isTrailer && (
              <ShareButtons
                mediaId={mediaId}
                data={data}
                slug={slug}
                hashtags={hashtags}
                isDownloadAllowed={isDownloadAllowed}
                userId={userId}
                onDeleteSuccess={onCloseHandler}
              />
            )}
            <Box className={style.commentSection}>
              <Box fill>
                {typeof window !== 'undefined' && (isGuestUser || shouldLoadPubnub) && (
                  <Suspense>
                    <PubNubWrapper isGuest={isGuestUser}>
                      <Suspense>
                        <ChatInfoContext.Provider value={chatInfo}>
                          <Chat
                            configuration={legacyAlternateChatConfiguration}
                            channelId={mediaId}
                            mediaType={type}
                            isGuest={isGuestUser}
                          />
                        </ChatInfoContext.Provider>
                      </Suspense>
                    </PubNubWrapper>
                  </Suspense>
                )}

                {typeof window !== 'undefined' && mediaId && !shouldLoadPubnub && (
                  <Suspense>
                    <ChatInfoContext.Provider value={chatInfo}>
                      <Chat configuration={legacyAlternateChatConfiguration} channelId={mediaId} mediaType={type} />
                    </ChatInfoContext.Provider>
                  </Suspense>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  if (isDrawer) {
    return (
      <Layer
        data-testid="Media Modal"
        full="horizontal"
        animation="slide"
        position="bottom"
        className="!h-[93%] !max-w-full overflow-hidden !rounded-[20px] !rounded-b-none"
        onEsc={onCloseHandler}
        onClickOutside={onCloseHandler}
      >
        {content}
      </Layer>
    );
  }

  return (
    <Layer
      data-testid="Media Modal"
      animation="fadeIn"
      position="center"
      onClickOutside={onCloseHandler}
      onEsc={onCloseHandler}
      margin={{ horizontal: isMobileSize ? '0px' : '70px' }}
      full={isMobileSize}
      className={clsx(isMobileSize && style.fullModal, '!rounded-[8px]')}
    >
      {content}
    </Layer>
  );
};

export default ShareModal;
