import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { buildURL, get } from '@/shared/fetch';

import { Pagination } from '../types';
import { getInfiniteQueryFn, getQueryFn } from '../utils';
import { AdminPlaylistListItem, GetPlaylistsQueryParams, Playlist, PlaylistListItem } from './types';

export const getInfinitePlaylistsQueryOptions = ({ videos, pageSize = 10, ...params }: GetPlaylistsQueryParams = {}) => {
  const videoIds = videos ? [...videos].join(',') : undefined;
  const query = { videos: videoIds, pageSize, ...params };
  return infiniteQueryOptions({
    queryKey: ['playlists', 'list', query],
    initialPageParam: 1,
    queryFn: getInfiniteQueryFn<Pagination<PlaylistListItem>>('/playlists', query),
    getNextPageParam: (lastPage, _, lastPageParams) =>
      !lastPage ? 1 : lastPage.total > pageSize * lastPageParams ? lastPageParams + 1 : undefined,
    select: ({ pages }) =>
      pages.length > 0
        ? ({
            total: pages[pages.length - 1].total,
            list: pages.flatMap((page) => page.list),
          } as Pagination<PlaylistListItem>)
        : undefined,
  });
};

export const getInfiniteAdminPlaylistsQueryOptions = ({
  videos,
  pageSize = 10,
  ...params
}: GetPlaylistsQueryParams = {}) => {
  const videoIds = videos ? [...videos].join(',') : undefined;
  const query = { videos: videoIds, pageSize, ...params };
  return infiniteQueryOptions({
    queryKey: ['playlists', 'list', 'admin', query],
    initialPageParam: 1,
    queryFn: getInfiniteQueryFn<Pagination<AdminPlaylistListItem>>('/admin/playlists', query),
    getNextPageParam: (lastPage, _, lastPageParams) =>
      !lastPage ? 1 : lastPage.total > pageSize * lastPageParams ? lastPageParams + 1 : undefined,
    select: ({ pages }) =>
      pages.length > 0
        ? ({
            total: pages[pages.length - 1].total,
            list: pages.flatMap((page) => page.list),
          } as Pagination<AdminPlaylistListItem>)
        : undefined,
  });
};

export const getPlaylistQueryOption = (playlistId: string | undefined) =>
  queryOptions({
    queryKey: ['playlists', 'detail', playlistId],
    queryFn: getQueryFn<Playlist>(`/playlists/${playlistId}`),
    enabled: Boolean(playlistId),
  });

export const getPlaylist = (playlistId: string | undefined) => get<Playlist>(buildURL(`/playlists/${playlistId}`));
