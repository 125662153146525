import { useEffect, useRef, useState } from 'preact/hooks';
import { Permission } from 'zl-shared/dist/auth';

import { hasPermission } from '@/components/Authorize';
import HighlightsList from '@/components/HighlightsList';
import { HighlightsPlaylistListRef } from '@/components/HighlightsList/HighlightsPlaylistList';
import { HighlightsVideoListRef } from '@/components/HighlightsList/HighlightsVideoList';
import { HighlightType } from '@/components/HighlightsList/types';
import { toHighlightType } from '@/components/HighlightsList/utils';
import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import NotificationPermissionModal from '@/components/NotificationPermissionModal';
import ShareModal from '@/components/ShareModal/Modal';
import { setQueryParams, useQueryParams } from '@/hooks/route';
import useOnMount from '@/hooks/useOnMount';
import { Video } from '@/queries/media/types';
import { PlaylistListItem } from '@/queries/playlists/types';
import MobileMap from '@/routes/map/Mobile';
import { trackMP } from '@/shared/mp';

import HomeVideoSection, { MiniPlayerContentMode } from './HomeVideoSection';
import { useHabitatForStream, useIsAllHabitatsOffline } from './hooks';
import HomeHighlightVideoInfoBar from './InfoBar/HomeHighlightVideoInfoBar';
import HomeHighlightVideoWithPlaylistInfoBar from './InfoBar/HomeHighlightVideoWithPlaylistInfoBar';
import HomeLiveStreamInfoBar from './InfoBar/HomeLiveStreamInfoBar';

const HomeContent = () => {
  const isMobile = useIsMobileLayout();
  const ref = useRef<HTMLDivElement>(null);
  const queryParams = useQueryParams();
  const mediaId = queryParams.get('mediaId');
  const playlistId = queryParams.get('playlistId');
  const highlightType = toHighlightType(
    queryParams.get('type')?.toLowerCase(),
    hasPermission(Permission.Habitat.UploadMedia),
  );

  const isAllHabitatsOffline = useIsAllHabitatsOffline();
  const habitatForStream = useHabitatForStream();

  const [preferMuted, setPreferMuted] = useState<boolean | undefined>();
  const [miniPlayerContentMode, setMiniPlayerContentMode] = useState<MiniPlayerContentMode>('highlight');
  const [currentVideo, setCurrentVideo] = useState<Video | undefined>();

  const [shouldUsePopup, setShouldUsePopup] = useState(!!mediaId);
  const [isPlayingList, setIsPlayingList] = useState(false);
  const [isPlaylistMode, setIsPlaylistMode] = useState(!!playlistId);

  const highlightsListRef = useRef<HighlightsVideoListRef>(null);
  const playlistListRef = useRef<HighlightsPlaylistListRef>(null);

  const onNextVideo = () => {
    highlightsListRef.current?.next();
    playlistListRef.current?.next();
  };

  const onVideoChange = (video: Video) => {
    setCurrentVideo(video);
    setQueryParams({ mediaId: video?._id }, true);
    setShouldUsePopup(false);
    if (!isMobile) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onVideoClick = (highlight: Video, index: number) => {
    setMiniPlayerContentMode('live-stream');
    setIsPlayingList(true);
    trackMP('home-highlight-content-clicked', { indexInList: index, highlight });
  };

  const onPlaylistChange = (playlist: PlaylistListItem) => {
    setQueryParams({ playlistId: playlist._id, mediaId: undefined }, true);
  };

  const onPlaylistClick = () => {
    setMiniPlayerContentMode('live-stream');
    setIsPlaylistMode(true);
    setIsPlayingList(true);
  };

  const onSwitchContentMode = () => {
    setMiniPlayerContentMode((prev) => (prev === 'highlight' ? 'live-stream' : 'highlight'));
    trackMP('home-switched-miniplayer-mode', { miniPlayerCurrentContentMode: miniPlayerContentMode });
  };

  const onLoadMore = () => {
    trackMP('home-fetch-more-clicked', {
      miniPlayerCurrentContentMode: miniPlayerContentMode,
      habitatId: habitatForStream?._id,
      habitatName: habitatForStream?.title,
      zooName: habitatForStream?.zoo.name,
    });
  };

  const onCloseMiniPlayer = () => {
    setCurrentVideo(undefined);
    highlightsListRef.current?.stop();
    playlistListRef.current?.stop();
    setIsPlayingList(false);
    setQueryParams({ mediaId: undefined }, true);
    trackMP('home-mini-player-closed', { currentHighlight: currentVideo });
  };

  const onChangeHighlightType = (type: HighlightType) => {
    if (type !== highlightType) {
      setQueryParams({ type, playlistId: undefined }, true);
      if (type === 'highlight') {
        setCurrentVideo(undefined);
        setQueryParams({ mediaId: undefined }, true);
      }
    }
  };

  // set highlight to first video if all habitats are offline
  useEffect(() => {
    if (isAllHabitatsOffline && !mediaId) {
      setPreferMuted(true);
      setMiniPlayerContentMode('live-stream');
      setIsPlayingList(true);
    }
  }, [isAllHabitatsOffline, mediaId]);

  useOnMount(() => {
    trackMP('home-visit', {
      currentHabitatId: habitatForStream?._id,
      currentHabitatInfo: habitatForStream,
      areAllHabitatsOffline: isAllHabitatsOffline,
    });
  });

  return (
    <div ref={ref} className="mobile:flex mobile:h-full mobile:flex-col mobile:overflow-hidden mobile:bg-white">
      <div className="top-0 z-10 mobile:shrink-0">
        <HomeVideoSection
          highlight={currentVideo}
          habitat={habitatForStream}
          miniPlayerContentMode={miniPlayerContentMode}
          onNextHighlight={onNextVideo}
          onSwitchContentMode={onSwitchContentMode}
          onCloseMiniPlayer={onCloseMiniPlayer}
          preferMuted={preferMuted}
          onMuteChange={setPreferMuted}
        />
        {miniPlayerContentMode === 'highlight'
          ? habitatForStream && <HomeLiveStreamInfoBar habitat={habitatForStream} />
          : currentVideo &&
            (isPlaylistMode && playlistId ? (
              <HomeHighlightVideoWithPlaylistInfoBar
                video={currentVideo}
                playlistId={playlistId}
                onNextVideo={onNextVideo}
                onBackToAllPlaylists={() => {
                  setIsPlaylistMode(false);
                  if (playlistId && !playlistListRef.current?.hasPlaylist(playlistId)) {
                    setQueryParams({ playlistId: undefined }, true);
                  }
                }}
              />
            ) : (
              <HomeHighlightVideoInfoBar highlight={currentVideo} onNextHighlight={onNextVideo} />
            ))}
      </div>
      <div className="relative mobile:grow mobile:overflow-y-auto mobile:bg-secondary-green desktop:pb-3">
        {isMobile ? (
          <>
            <MobileMap />
            <NotificationPermissionModal />
          </>
        ) : (
          <>
            <HighlightsList.Header
              type={highlightType}
              onTypeChange={onChangeHighlightType}
              className="sticky top-0 z-[1] px-4"
            />

            {highlightType === 'highlight' ? (
              <HighlightsList.PlaylistList
                key={highlightType}
                ref={playlistListRef}
                play={isPlayingList}
                playlistIdFromURL={playlistId}
                videoIdFromURL={mediaId}
                isExpanded={isPlaylistMode}
                onVideoChange={onVideoChange}
                onVideoClick={onVideoClick}
                onPlaylistChange={onPlaylistChange}
                onPlaylistClick={onPlaylistClick}
                className="flex min-h-full flex-col px-4"
              />
            ) : (
              <HighlightsList.VideoList
                key={highlightType}
                ref={highlightsListRef}
                play={isPlayingList}
                type={highlightType}
                onVideoChange={onVideoChange}
                onVideoClick={onVideoClick}
                onLoadMore={onLoadMore}
                className="px-4"
              />
            )}
          </>
        )}
      </div>

      {shouldUsePopup && mediaId && (
        <ShareModal
          open
          shouldLoadPubnub
          mediaId={mediaId}
          isDrawer={isMobile}
          onClose={() => {
            setCurrentVideo(undefined);
            setShouldUsePopup(false);
            if (isMobile) {
              setQueryParams({ mediaId: undefined, playlistId: undefined, type: undefined }, true);
            } else {
              setQueryParams({ mediaId: undefined }, true);
            }
          }}
        />
      )}
    </div>
  );
};

export default HomeContent;
