import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { adminUploadMediaMutationFn } from '@/queries/media';
import { AdminUploadMediaRequest, AdminUploadMediaResponse } from '@/queries/media/types';

// eslint-disable-next-line import/prefer-default-export
export const useAdminUploadMediaMutation = (
  options?: Omit<UseMutationOptions<AdminUploadMediaResponse, Error, AdminUploadMediaRequest, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: adminUploadMediaMutationFn,
    onSuccess: async (...args) => {
      queryClient.invalidateQueries({ queryKey: ['videos', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['media', 'list'] });
      options?.onSuccess?.(...args);
    },
  });
};
