import { queryOptions } from '@tanstack/react-query';

import { getQueryFn } from '../utils';
import { Event } from './types';

const FAR_FUTURE_DATE = Date.parse('3000-01-01T00:00:00Z');

export const getEventsQueryOptions = (params: {
  startTime: number;
  endTime?: number;
  habitatIds?: string[];
  animals?: string[];
  zoos?: string[];
  events?: string[];
  pageSize?: number;
}) => {
  const queryParams = { pageSize: 99, endTime: FAR_FUTURE_DATE, ...params };
  return queryOptions({
    queryKey: ['schedules', 'list', queryParams],
    queryFn: getQueryFn<Event[]>('/schedules/events', queryParams),
  });
};

export const getEventQueryOptions = (scheduleId: string | null | undefined) =>
  queryOptions({
    queryKey: ['schedules', 'details', scheduleId],
    queryFn: getQueryFn<Event>(`/schedules/events/${scheduleId}`),
    enabled: !!scheduleId,
  });
