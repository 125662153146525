import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'preact/compat';
import { Permission } from 'zl-shared/dist/auth';

import { cn } from '@/lib/utils';
import UploadMediaButton from '@/routes/habitat/components/Album/UploadMediaButton';

import { hasPermission } from '../Authorize';
import { HighlightType } from './types';

interface HighlightsListHeaderProps extends HTMLAttributes<HTMLDivElement> {
  type: HighlightType;
  onTypeChange: (type: HighlightType) => void;
}

const TYPES = [
  {
    label: 'Highlights',
    value: 'highlight',
    hidden: false,
  },
  {
    label: 'Latest Clips',
    value: 'clip',
    hidden: false,
  },
  {
    label: 'All Highlights (Admin)',
    value: 'curated',
    hidden: true,
  },
] as const;

const HighlightsListHeader = ({ onTypeChange, type: highlightType, className, ...rest }: HighlightsListHeaderProps) => {
  const types = hasPermission(Permission.Habitat.UploadMedia) ? TYPES : TYPES.filter((type) => !type.hidden);

  return (
    <div
      className={cn('preflight flex justify-between gap-2 bg-white py-3 mobile:flex-wrap', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div className="flex min-w-0 grow items-center">
        <div
          role="radiogroup"
          aria-label="Highlights filter"
          className="preflight preflight-fieldset flex items-center gap-2"
        >
          {types.map(({ label, value, hidden }) => (
            <a
              data-native
              key={value}
              role="radio"
              href={`?type=${value}`}
              tabIndex={0}
              aria-checked={highlightType === value}
              data-hidden={hidden}
              className="preflight preflight-a rounded-lg bg-primary-green bg-opacity-15 px-2 py-1 text-xs/none text-primary-green hover:bg-opacity-100 hover:text-white aria-checked:bg-opacity-100 aria-checked:text-white data-[hidden=true]:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                onTypeChange(value);
              }}
            >
              {label}
            </a>
          ))}
        </div>
      </div>
      {hasPermission(Permission.Habitat.UploadMedia) && highlightType === 'curated' && (
        <div className="">
          <UploadMediaButton
            disallowSendToChat
            uploadToTab="curated"
            className="!min-w-0"
            label={
              <>
                <FontAwesomeIcon icon={faUpload} />
                <span className="sr-only">Upload Curated</span>
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default HighlightsListHeader;
