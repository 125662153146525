import UploadMediaModal from './UploadMediaModal';
import UploadMediaModalTrigger from './UploadMediaModalTrigger';

type CompositedModal = typeof UploadMediaModal & {
  Trigger: typeof UploadMediaModalTrigger;
};

const Modal = UploadMediaModal;

(Modal as CompositedModal).Trigger = UploadMediaModalTrigger;

export default Modal as CompositedModal;
