import { HighlightType } from './types';

export const toHighlightType = (type: string | undefined, isAdmin: boolean): HighlightType => {
  if (type === 'highlight') return 'highlight';
  if (type === 'curated' && isAdmin) return 'curated'; // only admins can see curated highlights tab
  return type === 'clip' ? 'clip' : 'highlight';
};

export const isNew = (date: string | undefined) => {
  if (!date) return false;
  return Date.parse(date) > Date.now() - 1000 * 60 * 60 * 24;
};
