import { format } from 'date-fns';
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'preact/compat';

import { cn } from '@/lib/utils';

import { usePlaylistState } from './hooks';
import NewTag from './NewTag';
import { PlaylistProps, PlaylistRef } from './types';
import { isNew } from './utils';

const PlaylistWidget = (
  { playlistIdFromURL, videoIdFromURL, className, play, onVideoChange, onVideoClick }: PlaylistProps,
  ref: ForwardedRef<PlaylistRef>,
) => {
  const [listHeight, setListHeight] = useState<number | undefined>();
  const divRef = useRef<HTMLDivElement>(null);

  const { data, videoIndex, videoChangeHandler } = usePlaylistState({
    playlistIdFromURL,
    videoIdFromURL,
    play,
    onVideoChange,
    onVideoClick,
    ref,
  });

  const latestVideo = useMemo(
    () =>
      (data?.videos?.length ?? 0) > 0
        ? data?.videos.reduce(
            (latestVide, video) =>
              Date.parse(video.creationDate) > Date.parse(latestVide.creationDate) ? video : latestVide,
            data?.videos[0],
          )
        : undefined,
    [data?.videos],
  );

  useEffect(() => {
    const handler = () => {
      const div = divRef.current;
      if (!div) return;
      const { height } = div.getBoundingClientRect();
      setListHeight(height);
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [data]);

  return (
    <div className={cn('grid grid-cols-3 gap-3 rounded-xl bg-grey-5', className)}>
      <div ref={divRef} className="relative aspect-video rounded-xl">
        <img
          src={(videoIndex != null ? data?.videos?.[videoIndex]?.thumbnailURL : undefined) ?? latestVideo?.thumbnailURL}
          alt={data?.title}
          className="preflight preflight-img rounded-lg"
        />
        <div className="absolute inset-x-0 bottom-0 flex items-center justify-between gap-2 rounded-b-lg bg-[#3A3A3A]/80 px-2 py-1 text-white">
          <h5 className="preflight preflight-h5 line-clamp-1">{data?.title}</h5>
          <p className="preflight preflight-p shrink-0 text-xs">
            {data?.videos.length} Episode{data?.videos.length === 1 ? '' : 's'}
          </p>
        </div>
      </div>
      <div className="col-span-2 flex overflow-y-auto" style={{ height: listHeight }}>
        <ul className="preflight preflight-ul divide-y divide-[#D9D9D9] pr-3">
          {data?.videos.map((video, index) => (
            <li key={video._id} className="preflight py-3">
              <a
                data-native
                aria-current={videoIndex === index}
                className="preflight preflight-a group"
                href={`/highlights?type=highlight&playlistId=${data._id}&id=${video._id}`}
                onClick={(e) => {
                  e.preventDefault();
                  videoChangeHandler(video, index);
                  onVideoClick?.(video, index);
                }}
              >
                <div className="inline-flex items-center gap-1">
                  {isNew(video.creationDate) && <NewTag />}
                  <span className="line-clamp-1 min-w-0 font-medium text-grey-1 group-hover:text-secondary-green group-aria-[current=true]:text-secondary-green">
                    {video.title}
                  </span>
                </div>
                <br />
                <span className="preflight preflight-span mt-1 inline-block text-xs text-grey-6">
                  {format(Date.parse(video.creationDate), 'MMM do, yyyy')}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default forwardRef(PlaylistWidget);
