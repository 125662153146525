import AnimalIcon from 'Components/AnimalIcon';
import { hasPermission } from 'Components/Authorize';
import { OutlineButton, PrimaryButton } from 'Components/Buttons';
import { Box, Text } from 'grommet';
import { memo } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { buildURL } from 'Shared/fetch';
import useFetch from 'use-http';
import { Permission } from 'zl-shared';

import EditVideoModal from '@/components/EditVideoModal';
import { setShareModalData } from '@/components/ShareModal/shareModalSlice/actions';
import { useSelector } from '@/redux/helper';
import { updateAlbumData } from '@/redux/slices/myAlbum/actions';

import { formatTimeAgoOrTimestamp } from '../../../helpers';
import { updateHabitatAlbumData } from '../../../routes/habitat/components/Album/actions';
import style from './style.scss';

const EditTitle = ({ mediaId, data, maxLength = 100, isTrailer, onUpdate, editInPopup = false }) => {
  const userId = useSelector((state) => state.user.userId);
  const shareModalData = useSelector((state) => state.habitat.shareModal);
  // TODO unify data and shareModalData, not all modals use redux some are standalone modals
  const { title = '', userId: ownerId, profile, username } = data ?? {};

  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(title);

  const { post, response, error, loading } = useFetch('', {
    credentials: 'include',
    cachePolicy: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
  });

  useEffect(() => {
    setText(title);
    setEditMode(false);
  }, [title]);

  const submitHandler = async () => {
    await post(buildURL(`/media/${mediaId}`), { title: text });

    if (response?.ok && response?.data?.media && !error) {
      // update data in modal
      dispatch(setShareModalData({ ...shareModalData, data: response.data.media }));
      // update data in album card inside habitat and my album
      const payload = { id: mediaId, data: response.data.media };
      dispatch(updateHabitatAlbumData(payload));
      dispatch(updateAlbumData(payload));
      onUpdate?.(response.data);
    }

    setEditMode(false);
  };

  return (
    <div id={mediaId} className={style.editTitleContainer}>
      <div className={style.user}>
        <a className="preflight preflight-a" href={`/u/${username}`}>
          <AnimalIcon width={30} animalIcon={profile?.animal || profile?.animalIcon} color={profile?.color} />
        </a>
        <div className={style.userName}>
          <a className="preflight preflight-a font-semibold" href={`/u/${username}`}>
            <span>{username}</span>
          </a>
          <span className={style.time}>{`${formatTimeAgoOrTimestamp(new Date(data.createdAt || data.creationDate))}`}</span>
        </div>
      </div>

      <div className={style.titleContainer}>
        {!editMode && (
          <>
            <span className={style.title}>{title}</span>
            {editInPopup && hasPermission(Permission.Habitat.UploadMedia) ? (
              <EditVideoModal.Trigger
                videoId={mediaId}
                defaultThumbnailURL={data?.thumbnailURL}
                defaultTitle={title}
                canEditPlaylists={!data?.disabled}
                onUpdate={onUpdate}
                render={(setIsOpen) => (
                  <button className={style.editButton} type="button" onClick={() => setIsOpen(true)}>
                    Edit
                  </button>
                )}
              />
            ) : (
              (userId === ownerId || hasPermission(Permission.Habitat.EditMedia)) &&
              !isTrailer && (
                <button className={style.editButton} type="button" onClick={() => setEditMode(!editMode)}>
                  Edit
                </button>
              )
            )}
          </>
        )}

        {editMode && (
          <>
            <Box className={style.textarea}>
              <textarea
                placeholder="Type your description"
                value={text}
                onChange={(e) => setText(e.target.value)}
                maxLength={maxLength}
              />
              <span className={style.letterCount} style={{ color: text.length === maxLength ? 'var(--red)' : 'unset' }}>
                {`${text.length}/${maxLength}`}
              </span>
            </Box>
            {error && (
              <Box align="center" margin={{ top: '20px' }}>
                <Text color="red">Try again!</Text>
              </Box>
            )}

            <Box margin={{ vertical: '15px', horizontal: '10px' }} direction="row" justify="end">
              <OutlineButton
                style={{ minWidth: '75px', marginRight: '10px' }}
                label="Cancel"
                size="small"
                onClick={() => setEditMode(false)}
                justify="center"
              />
              <PrimaryButton
                style={{ minWidth: '75px' }}
                label="Save"
                size="small"
                loading={loading}
                disabled={loading || text.length === 0 || text.length > maxLength}
                onClick={submitHandler}
                justify="center"
              />
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(EditTitle);
