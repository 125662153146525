import { useInfiniteQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'preact/compat';
import { useEffect, useImperativeHandle, useRef, useState } from 'preact/hooks';

import { setQueryParams } from '@/hooks/route';
import { Video } from '@/queries/media/types';
import { getInfinitePlaylistsQueryOptions } from '@/queries/playlists';
import { PlaylistListItem } from '@/queries/playlists/types';

import { PrimaryButton } from '../Buttons';
import { useIsMobileLayout } from '../LayoutDetector/hooks';
import Loader from '../Loader';
import NewTag from './NewTag';
import Playlist from './Playlist';
import PlaylistWidget from './PlaylistWidget';
import { PlaylistRef } from './types';
import { isNew } from './utils';

interface HighlightsPlaylistListProps {
  className?: string;
  play?: boolean;
  playlistIdFromURL?: string | null;
  videoIdFromURL?: string | null;
  isExpanded?: boolean;
  onVideoChange?: (video: Video, index: number) => void;
  onVideoClick?: (video: Video, index: number) => void;
  onPlaylistChange?: (playlist: PlaylistListItem, index: number) => void;
  onPlaylistClick?: (playlist: PlaylistListItem, index: number) => void;
  onLoadMore?: () => void;
}

export interface HighlightsPlaylistListRef {
  next: () => void;
  hasPlaylist: (id: string) => boolean;
  stop: () => void;
}

const HighlightsPlaylistList = (
  {
    className,
    play,
    playlistIdFromURL,
    videoIdFromURL,
    isExpanded,
    onVideoChange,
    onVideoClick,
    onLoadMore,
    onPlaylistChange,
    onPlaylistClick,
  }: HighlightsPlaylistListProps,
  ref: ForwardedRef<HighlightsPlaylistListRef>,
) => {
  const isMobile = useIsMobileLayout();
  const listRef = useRef<HTMLUListElement>(null);
  const playlistRef = useRef<PlaylistRef>(null);
  const [playlistIndex, setPlaylistIndex] = useState<number | undefined>();

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery(
    getInfinitePlaylistsQueryOptions({ pageSize: 12 }),
  );

  useImperativeHandle(ref, () => ({
    next: () => {
      if (isExpanded || playlistRef.current?.hasNext()) {
        playlistRef.current?.next();
      } else {
        if (!data?.list.length) return;
        const newIndex = ((playlistIndex ?? 0) + 1) % data.list.length;
        setPlaylistIndex(newIndex);
        onPlaylistChange?.(data.list[newIndex], newIndex);
      }
    },
    stop: () => {
      setPlaylistIndex(undefined);
      playlistRef.current?.stop();
    },
    hasPlaylist: (id: string) => !!data?.list.some((playlist) => playlist._id === id),
  }));

  const loadMoreHandler = () => {
    fetchNextPage();
    onLoadMore?.();
  };

  useEffect(() => {
    if (playlistIdFromURL) {
      const index = data?.list?.findIndex((playlist) => playlist._id === playlistIdFromURL);
      if (index != null && index >= 0) {
        setPlaylistIndex(index);
      }
    }
  }, [playlistIdFromURL, data?.list]);

  const hasData = (data?.list.length ?? 0) > 0;
  const onPlaylistChangeRef = useRef(onPlaylistChange);
  onPlaylistChangeRef.current = onPlaylistChange;
  const firstPlaylistRef = useRef(data?.list[0]);
  firstPlaylistRef.current = data?.list[0];
  useEffect(() => {
    if (play && !playlistIdFromURL && hasData && firstPlaylistRef.current) {
      setQueryParams({ playlistId: firstPlaylistRef.current._id }, true);
      onPlaylistChangeRef.current?.(firstPlaylistRef.current, 0);
    }
  }, [play, hasData, playlistIdFromURL]);

  return (
    <div className={className}>
      <div className="relative flex grow flex-col">
        {playlistIdFromURL && !isMobile && (
          <PlaylistWidget
            play={play}
            playlistIdFromURL={playlistIdFromURL}
            videoIdFromURL={videoIdFromURL}
            ref={playlistRef}
            onVideoChange={onVideoChange}
            onVideoClick={onVideoClick}
            className="mb-2"
          />
        )}
        <ul ref={listRef} className="preflight preflight-ul grid grid-cols-2 gap-2 desktop:grid-cols-3 desktop:gap-3">
          {data?.list?.map((playlist, index) => (
            <li data-group="Playlist List Item" key={playlist._id} data-id={playlist._id} className="mobile:scroll-m-2">
              <a
                data-native
                href={`/highlights?type=highlight&playlistId=${playlist._id}`}
                aria-disabled={playlist.disabled}
                className={clsx('preflight preflight-a')}
                onClick={(e) => {
                  e.preventDefault();
                  setQueryParams({ playlistId: playlist._id }, true);
                  onPlaylistClick?.(playlist, index);
                }}
              >
                <div className="relative">
                  <div className="preflight relative overflow-hidden rounded transition-opacity hover:opacity-80 desktop:rounded-lg">
                    <img
                      className="preflight preflight-img aspect-video"
                      src={playlist.thumbnailURL}
                      alt={playlist.title}
                      loading="lazy"
                    />
                    {play && playlistIndex === index && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black/75">
                        <div className="flex h-6 items-end gap-0.5 desktop:h-10">
                          <span className="h-[60%] w-2 animate-soundbar bg-white desktop:w-4" />
                          <span className="h-[30%] w-2 animate-soundbar bg-white [animation-delay:-2.2s] desktop:w-4" />
                          <span className="h-[75%] w-2 animate-soundbar bg-white [animation-delay:-3.7s] desktop:w-4" />
                        </div>
                      </div>
                    )}
                    {isNew(playlist.latestVideoUploadedAt) && (
                      <NewTag className="absolute left-2 top-2 desktop:left-3 desktop:top-3" />
                    )}
                    <div className="absolute inset-x-0 bottom-0 flex items-center justify-between gap-2 rounded-b bg-[#3A3A3A]/80 px-2 py-1 text-white desktop:rounded-b-lg">
                      <h5 className="preflight preflight-h5 line-clamp-1 text-[9px] leading-normal desktop:text-base">
                        {playlist.title}
                      </h5>
                      <p className="preflight preflight-p shrink-0 text-[8px] leading-normal desktop:text-xs">
                        {playlist.videos.length} Episode{playlist.videos.length === 1 ? '' : 's'}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
        {isFetchingNextPage && <Loader color="var(--charcoal)" className="my-4" width="64px" height="64px" />}
        {hasNextPage && !isFetchingNextPage && (
          <div className="my-4 flex justify-center">
            <PrimaryButton label="Load More" size={isMobile ? 'medium' : 'large'} onClick={loadMoreHandler} />
          </div>
        )}
        {isMobile && (
          <Playlist
            ref={playlistRef}
            playlistIdFromURL={playlistIdFromURL}
            videoIdFromURL={videoIdFromURL}
            play={play}
            className={clsx(
              'absolute inset-0 overflow-auto bg-white py-4 transition duration-300',
              playlistIdFromURL && isExpanded
                ? 'translate-x-0 opacity-100'
                : 'pointer-events-none translate-x-full opacity-0',
            )}
            onVideoChange={onVideoChange}
            onVideoClick={onVideoClick}
          />
        )}
      </div>
    </div>
  );
};

export default forwardRef(HighlightsPlaylistList);
