import { ReactNode, StateUpdater, useState } from 'preact/compat';

import UploadMediaModal, { UploadMediaModalProps } from './UploadMediaModal';

interface UploadMediaModalTriggerProps extends UploadMediaModalProps {
  render: (setIsOpen: StateUpdater<boolean>, isOpen: boolean) => ReactNode;
}

const UploadMediaModalTrigger = ({ render, onClose, ...rest }: UploadMediaModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {render(setIsOpen, isOpen)}
      {isOpen && (
        <UploadMediaModal
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          onClose={() => {
            onClose?.();
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default UploadMediaModalTrigger;
