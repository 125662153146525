import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ForwardedRef } from 'preact/compat';
import { useEffect, useImperativeHandle, useMemo, useRef } from 'preact/hooks';

import { getInfiniteVideosQueryOptions } from '@/queries/media';
import { Video } from '@/queries/media/types';
import { getPlaylistQueryOption } from '@/queries/playlists';

import { HighlightType, PlaylistProps, PlaylistRef } from './types';

const QUERY_KEY_PARAMS = { highlight: '3', sortOrder: 'desc', pageSize: 12 };

export const useVideoList = (highlightType: HighlightType) => {
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery({
    ...getInfiniteVideosQueryOptions({ ...QUERY_KEY_PARAMS, type: highlightType }),
    enabled: highlightType !== 'highlight',
  });

  const fetchMoreIfNeeded = (index: number) => {
    if (!data || isFetchingNextPage || !hasNextPage) return;
    // fetch more highlights if we are near the end
    if (index > data.list.length - 3) {
      fetchNextPage();
    }
  };

  return {
    data,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
    fetchMoreIfNeeded,
  };
};

export const usePlaylistState = ({
  playlistIdFromURL,
  play,
  videoIdFromURL,
  onVideoChange,
  ref,
}: PlaylistProps & { ref: ForwardedRef<PlaylistRef> }) => {
  const { data } = useQuery(getPlaylistQueryOption(playlistIdFromURL ?? ''));
  const [video, videoIndex] = useMemo(() => {
    if (!data) return [undefined, undefined];
    const index = data.videos.findIndex((video) => video._id === videoIdFromURL);
    return [data.videos[index], index] as const;
  }, [data, videoIdFromURL]);

  const videoChangeHandler = (video: Video, index: number) => {
    if (!data) return;
    onVideoChange?.(video, index);
  };

  useImperativeHandle(ref, () => ({
    hasNext: () => {
      if (!data || !data.videos.length) return false;
      return data.videos.length > (videoIndex ?? 0) + 1;
    },
    next: () => {
      if (!data || !data.videos.length) return;
      const nextIndex = ((videoIndex ?? 0) + 1) % data.videos.length;
      onVideoChange?.(data.videos[nextIndex], nextIndex);
    },
    stop: () => {},
  }));

  const hasData = (data?.videos.length ?? 0) > 0;
  const onVideoChangeRef = useRef(onVideoChange);
  onVideoChangeRef.current = onVideoChange;
  const videoRef = useRef<{ video: Video; videoIndex: number } | undefined>();
  useEffect(() => {
    if (video && videoIndex != null) {
      videoRef.current = { video, videoIndex };
    } else if (data?.videos[0]) {
      videoRef.current = { video: data?.videos[0], videoIndex: 0 };
    } else {
      videoRef.current = undefined;
    }
  }, [data, video, videoIndex]);
  useEffect(() => {
    if (play && playlistIdFromURL && hasData) {
      if (videoRef.current) {
        onVideoChangeRef.current?.(videoRef.current.video, videoRef.current.videoIndex);
      }
    }
  }, [play, hasData, playlistIdFromURL]);

  return {
    data,
    video,
    videoIndex,
    videoChangeHandler,
  };
};
