import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { followHabitatMutationFn, unfollowHabitatMutationFn } from '@/queries/habitats';
import { useSelector } from '@/redux/helper';
import { toggleFavoriteHabitat } from '@/redux/slices/user/actions';
import { trackMP } from '@/shared/mp';

export interface FollowHabitatTrackingData {
  habitatName?: string;
  habitatId: string;
  place?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useFollowHabitat = (
  habitatId: string | null | undefined,
  {
    trackingData,
    onFollow,
    onUnfollow,
  }: { trackingData?: FollowHabitatTrackingData; onFollow?: () => void; onUnfollow?: () => void } = {},
) => {
  const dispatch = useDispatch();
  const isFavorited = useSelector((state) => (habitatId ? state.user?.favoriteHabitats?.includes(habitatId) : false));
  const trackingDataRef = useRef(trackingData);
  trackingDataRef.current = trackingData;

  const {
    mutate: followHabitat,
    isPending: isFollowingPending,
    isError: isFollowingError,
    reset: resetFollowing,
  } = useMutation({
    mutationFn: followHabitatMutationFn,
    onSuccess: () => {
      dispatch(toggleFavoriteHabitat(habitatId ?? ''));
      onFollow?.();
    },
  });

  const {
    mutate: unfollowHabitat,
    isPending: isUnfollowingPending,
    isError: isUnfollowingError,
    reset: resetUnfollowing,
  } = useMutation({
    mutationFn: unfollowHabitatMutationFn,
    onSuccess: () => {
      dispatch(toggleFavoriteHabitat(habitatId ?? ''));
      onUnfollow?.();
    },
  });

  const follow = useCallback(() => {
    if (!habitatId) {
      return;
    }

    followHabitat(habitatId);
    if (trackingDataRef.current) {
      trackMP('habitat-favourited', trackingDataRef.current);
    }
  }, [followHabitat, habitatId]);

  const unfollow = useCallback(() => {
    if (!habitatId) {
      return;
    }

    unfollowHabitat(habitatId);
    if (trackingDataRef.current) {
      trackMP('habitat-unfavourited', trackingDataRef.current);
    }
  }, [unfollowHabitat, habitatId]);

  const isPending = isFollowingPending || isUnfollowingPending;
  const isError = isFollowingError || isUnfollowingError;

  const favIcon = isError ? faTimes : isFavorited ? faHeartSolid : faHeart;

  useEffect(() => {
    if (!isFollowingError) {
      return undefined;
    }

    const id = setTimeout(() => {
      resetFollowing();
    }, 2000);

    return () => clearTimeout(id);
  }, [isFollowingError, resetFollowing]);

  useEffect(() => {
    if (!isUnfollowingError) {
      return undefined;
    }

    const id = setTimeout(() => {
      resetUnfollowing();
    }, 2000);

    return () => clearTimeout(id);
  }, [isUnfollowingError, resetUnfollowing]);

  return {
    favIcon,
    isError,
    isPending,
    isFavorited,
    follow,
    unfollow,
  };
};
