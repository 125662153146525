/* eslint-disable react/jsx-props-no-spreading */
import { useInfiniteQuery } from '@tanstack/react-query';
import { Grommet, SelectMultiple, SelectMultipleExtendedProps, ThemeType } from 'grommet';
import { debounce } from 'lodash-es';
import { useMemo, useState } from 'preact/hooks';

import grommetTheme from '@/grommetTheme';
import { getInfiniteAdminPlaylistsQueryOptions } from '@/queries/playlists';
import { AdminPlaylistListItem } from '@/queries/playlists/types';

interface PlaylistsSelectorProps
  extends Omit<SelectMultipleExtendedProps, 'value' | 'suggestions' | 'onChange' | 'options'> {
  value?: AdminPlaylistListItem[];
  onChange?: (newValue: AdminPlaylistListItem[]) => void;
}

const theme = {
  ...grommetTheme,
  select: {},
} as unknown as ThemeType;

const PlaylistsSelector = ({ value, onChange, ...rest }: PlaylistsSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const trimmedSearchText = searchText.trim();

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    ...getInfiniteAdminPlaylistsQueryOptions({ title: trimmedSearchText, pageSize: 20 }),
    enabled: isOpen,
  });

  const onSearch = useMemo(() => debounce(setSearchText, 500, { trailing: true }), []);

  return (
    <Grommet theme={theme}>
      <SelectMultiple
        showSelectedInline
        emptySearchMessage={
          <p className="preflight preflight-p my-2">
            No playlists found. Try to{' '}
            <a
              href="/admin/playlists"
              target="_blank"
              className="preflight preflight-a font-medium text-primary-green hover:text-secondary-green"
            >
              create a new playlist first
            </a>
            .
          </p>
        }
        value={value}
        labelKey="title"
        valueKey="_id"
        onMore={hasNextPage ? fetchNextPage : undefined}
        onSearch={onSearch}
        options={data?.list ?? []}
        onChange={({ value }) => onChange?.(value)}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setIsOpen(false);
          setSearchText('');
        }}
        {...rest}
      />
    </Grommet>
  );
};

export default PlaylistsSelector;
