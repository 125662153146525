import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { OutlineButton } from 'Components/Buttons';
import { Box, Heading, Text, TextInput } from 'grommet';
import { isEmpty } from 'lodash-es';
import { forwardRef } from 'preact/compat';
import { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'preact/hooks';

import { useDisallowAutoReloadEffect } from '@/hooks/disallowAutoReload';

import { fromUrlToS3, getHeaders, uploadFile } from './api';
import style from './style.scss';
import { makeHelpText } from './utils';

const RESOLUTION_CONSTRAINT = 'RESOLUTION_CONSTRAINT';
const FILE_SIZE_CONSTRAINT = 'FILE_SIZE_CONSTRAINT';
const ASPECT_RATIO_CONSTRAINT = 'ASPECT_RATIO_CONSTRAINT';
const ACCEPTED_FORMAT_CONSTRAINT = 'ACCEPTED_FORMAT_CONSTRAINT';
const WIDTH_CONSTRAINT = 'WIDTH_CONSTRAINT';
const HEIGHT_CONSTRAINT = 'HEIGHT_CONSTRAINT';

const ValidationLine = ({ error, text }) => <Text color={error ? 'status-error' : undefined}>{text}</Text>;

const Constraints = ({ constraints, errors }) =>
  constraints.map(({ type, text }, ind) => (
    // eslint-disable-next-line react/jsx-key
    <>
      <ValidationLine error={errors[type]} text={text} key={text} />
      {ind !== constraints.length - 1 && ', '}
    </>
  ));

const addSuffixToFilename = (filename, suffix) => {
  if (!suffix) {
    return filename;
  }
  const filenameParts = filename.split('.');
  const extension = filenameParts.pop();
  const baseFilename = filenameParts.join('.');
  if (!baseFilename) {
    return `${extension}${suffix ? `-${suffix}` : ''}.bin`;
  }
  return `${baseFilename}${suffix ? `-${suffix}` : ''}.${extension}`;
};

/**
 * Renders an image selector component with various constraints and validations.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.label] - The label for the image selector.
 * @param {string} [props.name] - The name for the image selector.
 * @param {string} [props.url] - The URL of the image.
 * @param {string} [props.placeholder] - The placeholder text for the input field.
 * @param {boolean} [props.required] - Whether the image is required.
 * @param {Object} [props.transform] - The transformation object for the media.
 * @param {number} [props.transform.maxWidth] - The maximum width of the transformed media.
 * @param {number} [props.transform.maxHeight] - The maximum height of the transformed media.
 * @param {[number, number]} [props.transform.forceAspectRatio] - The aspect ratio of the transformed media.
 * @param {string} [props.transform.outputFormat] - The output format of the transformed media.
 * @param {Object} [props.recommendedSettings] - The recommended settings for the media.
 * @param {number} [props.recommendedSettings.maxWidth] - The maximum width of the media.
 * @param {number} [props.recommendedSettings.maxHeight] - The maximum height of the media.
 * @param {[number, number]} [props.recommendedSettings.forceAspectRatio] - The aspect ratio of the media.
 * @param {string} [props.recommendedSettings.outputFormat] - The output format of the media.
 * @param {number} [props.recommendedSettings.fileSize] - The file size of the media in bytes.
 * @param {number} [props.recommendedSettings.duration] - The duration of the video in seconds.
 * @param {import('preact').RefObject} [props.previewRef] - The reference to the preview image element.
 * @param {Object} [props.constraints] - The constraints for the image.
 * @param {number} [props.constraints.maxResolution] - The maximum resolution of the image.
 * @param {number} [props.constraints.minResolution] - The minimum resolution of the image.
 * @param {number} [props.constraints.width] - The width of the image.
 * @param {number} [props.constraints.height] - The height of the image.
 * @param {number} [props.constraints.maxFileSize] - The maximum file size of the image.
 * @param {string} [props.constraints.aspectRatio] - The aspect ratio of the image. e.g. `16:9`
 * @param {string[] | readonly string[]} [props.constraints.acceptedFormats] - The accepted formats for the image.
 * @param {string} [props.helpText] - The help text for the component.
 * @param {string} [props.className] - The CSS class name for the component.
 * @param {Object} [props.style] - The inline styles for the component.
 * @param {(url: string) => void} [props.onChange] - The callback function for when the image URL changes.
 * @param {string} [props.filenameSuffix] - The suffix to append to the filename of the image.
 * @param {import('preact/compat').ForwardedRef} ref - The reference to the component.
 * @return {JSX.Element} The rendered image selector component.
 */
const ImageSelector = (
  {
    label,
    name,
    url,
    placeholder,
    required,
    transform,
    recommendedSettings,
    // preview image ref that we can used to validate resolution
    previewRef,
    constraints: { maxResolution, minResolution, width, height, maxFileSize, aspectRatio, acceptedFormats = [] } = {},
    helpText,
    className,
    style: styleProp,
    onChange,
    filenameSuffix,
  },
  ref,
) => {
  const [isLoading, setIsLoading] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [errors, setErrors] = useState({});
  const acceptedFormatsStr = acceptedFormats.map((format) => (format.endsWith('/*') ? format : `.${format}`)).join(',');
  const origin =
    process.env.PREACT_APP_IMAGE_CDN_ORIGIN ||
    `${process.env.PREACT_APP_HTTP_PROTOCOL}${process.env.PREACT_APP_API_AUTHORITY}`.replace('api.', '');
  const recommendedSettingsHelpText = useMemo(() => makeHelpText(recommendedSettings), [recommendedSettings]);

  useEffect(() => setErrorMsg(undefined), [url]);

  const validateConstraints = useCallback(async () => {
    const newErrors = {};
    const headers = await getHeaders(url, origin);

    if (acceptedFormats && acceptedFormats.length > 0 && ((!required && !isEmpty(url)) || required)) {
      const mimeType = headers.get('content-type');
      const [category, mediaFormat] = mimeType.split('/');
      if (
        !acceptedFormats.some((format) =>
          format.endsWith('/*')
            ? format.startsWith(category)
            : url?.toLowerCase().endsWith(format) || mediaFormat === format,
        )
      ) {
        newErrors[ACCEPTED_FORMAT_CONSTRAINT] = true;
      } else {
        newErrors[ACCEPTED_FORMAT_CONSTRAINT] = false;
      }
    }

    if (maxFileSize && maxFileSize < headers.get('content-length')) {
      newErrors[FILE_SIZE_CONSTRAINT] = true;
    } else {
      newErrors[FILE_SIZE_CONSTRAINT] = false;
    }

    // we assume the new image is loaded while we were waiting for head request in getHeaders
    if (previewRef?.current) {
      const imgWidth = previewRef.current.naturalWidth || previewRef.current.videoWidth;
      const imgHeight = previewRef.current.naturalHeight || previewRef.current.videoHeight;

      if (
        (maxResolution && (imgWidth > maxResolution || imgHeight > maxResolution)) ||
        (minResolution && (imgWidth < minResolution || imgHeight < minResolution))
      ) {
        newErrors[RESOLUTION_CONSTRAINT] = true;
      } else {
        newErrors[RESOLUTION_CONSTRAINT] = false;
      }

      if (width && width !== imgWidth) {
        newErrors[WIDTH_CONSTRAINT] = true;
      } else {
        newErrors[WIDTH_CONSTRAINT] = false;
      }
      if (height && height !== imgHeight) {
        newErrors[HEIGHT_CONSTRAINT] = true;
      } else {
        newErrors[HEIGHT_CONSTRAINT] = false;
      }

      if (aspectRatio) {
        const [width, height] = aspectRatio.split(':').map(Number);
        if (width / height !== imgWidth / imgHeight) {
          newErrors[ASPECT_RATIO_CONSTRAINT] = true;
        } else {
          newErrors[ASPECT_RATIO_CONSTRAINT] = false;
        }
      }
    }

    return newErrors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    url,
    previewRef,
    maxResolution,
    minResolution,
    maxFileSize,
    aspectRatio,
    // using string here instead of acceptedFormats
    // because array is passed and it's always different
    acceptedFormatsStr,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);
        const newErrors = await validateConstraints();
        setErrors(newErrors);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    const el = previewRef?.current;

    if (el) {
      el.addEventListener('load', onLoad);

      return () => {
        el.removeEventListener('load', onLoad);
      };
    }

    // no need to wait for load since we'll skip preview image in validateConstraints
    onLoad();
  }, [validateConstraints, previewRef]);

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let isValid = true;

      if (!required && isEmpty(url)) {
        return isValid;
      }

      try {
        const parsedUrl = new URL(url);
        const normalizeAssetHost = parsedUrl.hostname.replace('www.', '');
        const originUrl = new URL(origin);
        const normalizedLocationHost = originUrl.hostname.replace('www.', '');

        if (normalizeAssetHost !== normalizedLocationHost) {
          setErrorMsg(`Only URLs from ${origin} are allowed`);
          return false;
        }
      } catch (err) {
        // it's probably invalid URL
        console.error(err);
      }

      if (required && (!url || url.length === 0)) {
        setErrorMsg('Media is required');
        isValid = false;
      }

      try {
        setIsLoading(true);
        const newErrors = await validateConstraints();
        if (!isEmpty(newErrors)) {
          // TODO: scroll into view if there are new errors
          setErrors({ ...errors, ...newErrors });
        }

        // if any of newErrors is true
        if (Object.values(newErrors).some((e) => e)) {
          isValid = false;
        }

        return isValid;
      } catch (err) {
        setErrorMsg(`We had problems validating the image. Please check for failed network requests.\n${err}`);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
  }));

  const constraints = useMemo(() => {
    const constraints = [];

    if (maxResolution && minResolution) {
      constraints.push({
        type: RESOLUTION_CONSTRAINT,
        text: `Resolution: >${minResolution}, <${maxResolution}`,
      });
    } else if (maxResolution) {
      constraints.push({
        type: RESOLUTION_CONSTRAINT,
        text: `Resolution: <${maxResolution}`,
      });
    } else if (minResolution) {
      constraints.push({
        type: RESOLUTION_CONSTRAINT,
        text: `Resolution: >${minResolution}`,
      });
    }

    if (width) {
      constraints.push({
        type: WIDTH_CONSTRAINT,
        text: `Width: ${width}`,
      });
    }
    if (height) {
      constraints.push({
        type: HEIGHT_CONSTRAINT,
        text: `Height: ${height}`,
      });
    }

    if (maxFileSize) {
      if (maxFileSize < 1000) {
        constraints.push({
          type: FILE_SIZE_CONSTRAINT,
          text: `File: ${maxFileSize}B`,
        });
      } else if (maxFileSize < 1000 * 1000) {
        constraints.push({
          type: FILE_SIZE_CONSTRAINT,
          text: `File: ${Math.round(maxFileSize / 1_000)}kB`,
        });
      } else {
        constraints.push({
          type: FILE_SIZE_CONSTRAINT,
          text: `File: ${Math.round(maxFileSize / 1_000_000)}MB`,
        });
      }
    }

    if (aspectRatio) {
      constraints.push({
        type: ASPECT_RATIO_CONSTRAINT,
        text: `Aspect Ratio: ${aspectRatio}`,
      });
    }

    if (acceptedFormats) {
      if (acceptedFormats instanceof Array) {
        constraints.push({
          type: ACCEPTED_FORMAT_CONSTRAINT,
          text: `Accepted Formats: ${acceptedFormats.join(', ')}`,
        });
      } else {
        throw new Error('"acceptedFormats" accepts only arrays');
      }
    }

    return constraints;
  }, [maxResolution, minResolution, width, height, maxFileSize, aspectRatio, acceptedFormats]);

  const onInputChange = async ({ target }) => {
    try {
      setIsLoading(true);
      /** @type {File[]} */
      const file = target.files[0];
      const filename = addSuffixToFilename(file.name || file.filename || 'unnamed.bin', filenameSuffix);
      const { path } = await uploadFile(file, {
        maxResolution,
        acceptedFormats,
        maxFileSize,
        width,
        height,
        transform,
        filename,
      });
      onChange(`${origin}/${path}`);
    } catch (err) {
      const { error } = err.body ?? {};

      if (error === 'Invalid format') {
        setErrors({ ...errors, [ACCEPTED_FORMAT_CONSTRAINT]: true });
      } else if (error?.includes?.('File too large')) {
        setErrors({ ...errors, [FILE_SIZE_CONSTRAINT]: true });
      } else {
        console.error('upload failed', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onBlur = async ({ target }) => {
    if (url === target.value) {
      return;
    }

    if (!required && isEmpty(target.value)) {
      onChange(target.value);
      return;
    }

    const toURL = (value) => {
      try {
        return new URL(value);
      } catch (err) {
        return null;
      }
    };

    const urlObj = toURL(target.value);
    if (urlObj?.origin === origin) {
      onChange(target.value);
      return;
    }

    if (!urlObj) {
      setErrorMsg('Invalid URL');
      return;
    }

    try {
      setIsLoading(true);
      const { path } = await fromUrlToS3(target.value);
      onChange(`${origin}/${path}`);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useDisallowAutoReloadEffect();

  return (
    <div className={className} style={styleProp}>
      {label && (
        <Heading margin={{ top: '0', bottom: '5px' }} level="5">
          {label}
        </Heading>
      )}
      <TextInput
        placeholder={placeholder}
        name={name}
        value={url || undefined}
        onBlur={onBlur}
        icon={isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : undefined}
        disabled={isLoading}
        reverse
      />
      {errorMsg && (
        <Box>
          <Text style={{ whiteSpace: 'pre-line' }} color="status-error">
            {errorMsg}
          </Text>
        </Box>
      )}

      <div className={style.constraints}>
        <Constraints constraints={constraints} errors={errors} />
      </div>

      {recommendedSettingsHelpText && (
        <div className={style.constraints}>
          <Text as="h6" className="preflight preflight-h6">
            Recommended settings:
          </Text>
          <p className="preflight preflight-p flex flex-wrap gap-1">
            {recommendedSettingsHelpText.map((text, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text as="span" key={i.toString()}>
                {text}
                {i !== recommendedSettingsHelpText.length - 1 && ','}
              </Text>
            ))}
          </p>
        </div>
      )}

      {helpText && <div className={style.constraints}>{helpText}</div>}

      <Box direction="row">
        <div className={style.uploadBtnWrapper}>
          <input disabled={isLoading} onChange={onInputChange} type="file" name="file" accept={acceptedFormatsStr} />
          <OutlineButton size="small" disabled={isLoading} label="Upload" margin={{ right: '5px' }} />
        </div>

        <a download href={url} target="_blank" rel="noopener noreferrer" className={clsx(errorMsg && style.disabled)}>
          <OutlineButton size="small" label="Download" disabled={!!errorMsg} />
        </a>
      </Box>
    </div>
  );
};

export default forwardRef(ImageSelector);
