import { ReactNode } from 'preact/compat';

import { PrimaryButton } from '@/components/Buttons';
import UploadMediaModal from '@/components/UploadMediaModal';
import { UploadMediaModalProps } from '@/components/UploadMediaModal/UploadMediaModal';
import { useSelector } from '@/redux/helper';

interface UploadMediaButtonProps extends Omit<UploadMediaModalProps, 'habitatId' | 'onClose'> {
  label?: ReactNode;
  className?: string;
}

const UploadMediaButton = ({ label, className, ...props }: UploadMediaButtonProps) => {
  const habitatId = useSelector((state) => state.habitat.habitatInfo?._id);

  return (
    <UploadMediaModal.Trigger
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      habitatId={habitatId}
      render={(setIsOpen) => (
        <PrimaryButton
          size="small"
          label={label ?? 'Upload'}
          onClick={() => setIsOpen(true)}
          margin={{ left: 'auto' }}
          className={className}
        />
      )}
    />
  );
};

export default UploadMediaButton;
