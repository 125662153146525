import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';

import HabitatLink from '@/components/HabitatLink';
import { getHabitatQueryOptions } from '@/queries/habitats';
import { Video } from '@/queries/media/types';
import { getPlaylistQueryOption } from '@/queries/playlists';

import FollowButton from './FollowButton';

interface HomeHighlightVideoWithPlaylistInfoBarProps {
  video: Video;
  playlistId: string;
  onNextVideo?: () => void;
  onBackToAllPlaylists?: () => void;
}

const HomeHighlightVideoWithPlaylistInfoBar = ({
  video,
  playlistId,
  onNextVideo,
  onBackToAllPlaylists,
}: HomeHighlightVideoWithPlaylistInfoBarProps) => {
  const { data } = useQuery(getPlaylistQueryOption(playlistId));

  const { data: habitat } = useQuery({
    ...getHabitatQueryOptions(video.habitatId?.slug, video.habitatId?.zoo.slug),
    select: (data) => ({
      _id: data.habitat._id,
      title: data.habitat.title,
      conservationText: data.habitat.conservationText,
      profileImage: data.habitat.profileImage,
      zoo: data.habitat.zoo,
      slug: data.habitat.slug,
    }),
  });

  return (
    <div className="relative z-10 flex items-center justify-between bg-white p-4 shadow-1 desktop:bg-white desktop:text-black">
      <div className="flex items-center gap-8 pr-2 mobile:hidden">
        {habitat && (
          <div className="preflight flex shrink-0 items-center gap-9 border-r border-[#D9D9D9] pr-3">
            <div className="pb-3">
              <HabitatLink
                habitatId={habitat._id}
                slug={habitat.slug}
                zooSlug={habitat.zoo.slug}
                className="preflight preflight-a"
              >
                <div className="relative hover:opacity-75">
                  <img
                    className="preflight preflight-img size-16 overflow-hidden rounded-2xl object-contain"
                    src={habitat.profileImage}
                    alt={`${habitat.title} habitat profile`}
                  />
                  <div className="absolute -bottom-1/4 -right-1/4 z-10 flex size-10 items-center justify-center overflow-hidden rounded-full bg-white shadow-[0_4px_4px_rgba(0,0,0,0.15)]">
                    <img className="preflight preflight-img" src={habitat.zoo.logo} alt={habitat.zoo.name} />
                  </div>
                </div>
              </HabitatLink>
            </div>
            <div className="flex flex-col gap-1">
              <HabitatLink
                habitatId={habitat._id}
                slug={habitat.slug}
                zooSlug={habitat.zoo.slug}
                className="preflight preflight-a mb-1 text-lg font-medium text-grey-1 hover:text-grey-3"
              >
                {habitat.title}
              </HabitatLink>
              <div className="w-[120px]">
                <FollowButton habitatId={habitat._id} habitatName={habitat.title} place="home-playlist-info-bar" />
              </div>
            </div>
          </div>
        )}
        <div className="preflight flex min-w-0 flex-col justify-center">
          <h4 className="preflight preflight-h4 text-lg">{data?.title}</h4>
          <h5 className="preflight preflight-h5 line-clamp-1 text-lg font-medium">{video.title}</h5>
        </div>
      </div>
      <a
        data-native
        className="preflight preflight-a inline-flex items-center gap-1 text-xs text-secondary-green desktop:hidden"
        href="?type=highlight"
        onClick={(e) => {
          e.preventDefault();
          onBackToAllPlaylists?.();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        All Highlights
      </a>
      <div className="flex shrink-0 gap-2">
        {video.habitatId && (
          <HabitatLink
            habitatId={video.habitatId._id}
            slug={video.habitatId.slug}
            zooSlug={video.habitatId.zoo.slug}
            className="preflight preflight-a rounded-full border border-primary-green px-3 py-1 text-center text-xs font-medium text-primary-green hover:bg-beige-light desktop:hidden"
          >
            Visit Habitat
          </HabitatLink>
        )}
        {(data?.videos.length ?? 0) > 1 && (
          <button
            type="button"
            onClick={onNextVideo}
            className="preflight preflight-button rounded-full border border-transparent bg-primary-green px-3 py-1 text-xs font-medium text-white hover:bg-secondary-green desktop:px-4 desktop:py-2 desktop:text-sm desktop:font-medium"
          >
            Next Episode
          </button>
        )}
      </div>
    </div>
  );
};

export default HomeHighlightVideoWithPlaylistInfoBar;
